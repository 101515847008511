import { CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CImg, CInputGroup, CRow } from "@coreui/react"
import { useEffect, useState } from "react";
import { isEmail } from "../../helper";
import { Slide, ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { logUser, refresh, selectIsLoggedIn, setLoading } from "./store";
import { signIn } from "./api";
import { AuthResponseType } from "./type";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Input } from "../../assets";
import { Div, H5 } from "../layout/styled";

export const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    useEffect(() => {
        if (!isLoggedIn) {
            dispatch(refresh());
        }
    }, [dispatch, isLoggedIn]);
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const validated = !username || !password

    const handelSubmit = async () => {

        if (!isEmail(username)) {
            toast.error("Please enter valid email.")
            return false;
        }
        const data = {
            email: username,
            password
        }
        dispatch(setLoading(true));
        const api = await signIn(data);
        if (!api.statusCode) {
            const auth: AuthResponseType = api;
            dispatch(logUser(auth));
            history.push('/dashboard');

        } else {
            dispatch(setLoading(false));
        }
    }
    const handlePasswordRest = () => {
        
    }

    return (
        <>
            {!isLoggedIn && <Div className={'c-app c-default-layout flex-row align-items-center'}>
                <CContainer>
                    <CRow className={'justify-content-center'}>
                        <CCol md={'4'}>
                            <CCardGroup>
                                <CCard className={'p-4'}>
                                    <CCardBody>
                                        <CForm >
                                            <Div className="text-center">
                                                <CImg
                                                    src={'glocalview_logo.png'}
                                                    className="img-fluid w-75"
                                                    alt="logo-image"
                                                />
                                                <H5 className={'text-muted m-2'}>{'Sign in to start your session'}</H5>
                                            </Div>
                                            <CInputGroup className={'mb-3'}>
                                                <Input
                                                    type={'text'}
                                                    value={username}
                                                    onValueChange={setUsername}
                                                    leftIcon={'cil-user'}
                                                    placeholder={'Email'}
                                                />
                                            </CInputGroup>
                                            <CInputGroup className={'mb-4'}>
                                                <Input
                                                    type={'password'}
                                                    value={password}
                                                    onValueChange={setPassword}
                                                    leftIcon={'cil-lock-locked'}
                                                    placeholder={'Enter password..'}
                                                />
                                            </CInputGroup>
                                            <CRow>
                                                <CCol xs={'6'}>
                                                    <Button
                                                        buttonText={'Sign In'}
                                                        buttonType={'primary'}
                                                        className={'px-3'}
                                                        disabled={validated}
                                                        onClick={handelSubmit}
                                                    />
                                                </CCol>
                                                <CCol xs={'6'} className={'text-right'}>
                                                    <Button
                                                        buttonText={'Forgot password?'}
                                                        buttonType={'link'}
                                                        className={'px-0'}
                                                        disabled={true}
                                                        onClick={handlePasswordRest}
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CForm>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>
                <ToastContainer autoClose={5000} transition={Slide} />
            </Div>}
            {isLoggedIn && <Redirect to='/dashboard'/>}
            </>
    )
}