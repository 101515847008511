import { CContainer } from '@coreui/react';
import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { GlobalFooter, GlobalSidebar, GlobalHeader } from '../..';
import  authLayout  from '../../../auth/layout/auth';
import { Div, Main } from '../../styled';
import { GlobalLayoutProp } from '../../type';

const GlobalLayoutScreen = ({children}: GlobalLayoutProp) => {
    return (
        <Div className="c-app c-default-layout">
            <GlobalSidebar/>
            <Div className="c-wrapper">
                <GlobalHeader/>
                <Div className="c-body">
                    <Main className="c-main">
                        <CContainer fluid>
                            {children}
                        </CContainer>
                    </Main>
                </Div>
                <GlobalFooter/>
            </Div>
            <ToastContainer autoClose={5000} transition={Slide}/>
        </Div>
    )
}

const GlobalLayout = authLayout(GlobalLayoutScreen);

export default GlobalLayout;