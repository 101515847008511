import ApiWrapper from '../../service/ApiWrapper';

/* start sign in */
export const signIn = async (data:object) => {
    const path = `auth/user/login`;
    return await ApiWrapper.post(path, data);
}
/* end sign in */

/* start sign out */
export const signOut = async () => {
    return;
}
/* start sign out */

// Start Password Forget
export const passwordForget = async (data: object) => {
    const path = `auth/user/forget-password`;
    return await ApiWrapper.post(path, data)
}
// End Password Forget

// Password Reset API Start
export const passwordRest = async (data: object) => {
    const path = `auth/user/reset-password`;
    return await ApiWrapper.post(path, data)
}
// Password Reset API End