import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { signOut } from "./api";
import { AuthResponseType, AuthUserResponseType, AuthUserSettingResponseType } from "./type";

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        /*start common*/
        loading:false as boolean,
        message:'' as string,
        token:'' as string,
        /*end common*/

        /*start user*/
        user:{} as AuthUserResponseType,
        scopes: [] as string[],
        settings: [] as AuthUserSettingResponseType[],
        isLoggedIn: false as boolean,
        /*end user*/
    },
    reducers:{
        /*start common*/
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setAlert: (state, action:PayloadAction<string>) =>{
            state.message = action.payload;
        },
        setToken: (state, action:PayloadAction<string>) => {
            localStorage.setItem("token", action.payload);
            state.token = action.payload;
            state.isLoggedIn = true;
        }, 
        setRToken: (state, action:PayloadAction<string>) => {
            localStorage.setItem("refresh", action.payload);
            state.token = action.payload;
        }, 
        /*end common*/

        /*start user*/
        setUser: (state, action:PayloadAction<AuthUserResponseType>) => {
            state.user = action.payload;
        }, 
        setScope: (state, action:PayloadAction<string[]>) => {
            localStorage.setItem('scopes', JSON.stringify(action.payload));
            state.scopes = action.payload;
        },
        setSetting: (state, action:PayloadAction<AuthUserSettingResponseType[]>) => {
            localStorage.setItem('settings', JSON.stringify(action.payload))
            state.settings = action.payload;
        },
        setLoggedIn: (state, action:PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        refresh:state => {
            const token = localStorage.getItem("token");
            state.token = token || '';
            state.isLoggedIn = token? true:false;
        }
        /*end user*/
    }
});

export const {
    setLoading,
    setAlert,
    setToken,
    setRToken,
    setUser,
    setScope,
    setSetting,
    setLoggedIn,
    refresh
} = AuthSlice.actions;

/*start user*/
export const logUser = (auth: AuthResponseType) => (dispatch: any) => {
    dispatch(setUser(auth.user));
    dispatch(setToken(auth.token));
    dispatch(setRToken(auth.refresh));
    dispatch(setScope(auth.scope));
    dispatch(setSetting(auth.setting));
    dispatch(setLoading(false));
}
/*end user*/

/*start logout*/
export const logOut = () => async (dispatch: any) => {
    await signOut();
    localStorage.clear();
    dispatch(refresh());
}
/*end logout*/

export const selectLoading = (state:any) => state.auth.loading;
export const selectAlert = (state:any) => state.auth.message;
export const selectIsLoggedIn = (state: any) => state.auth.isLoggedIn;

export default AuthSlice.reducer;