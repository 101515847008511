import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from "@coreui/react"


export const Dashboard = () => {

    return (
        <div className={'c-app c-default-layout flex-row align-items-center'}>
            <CContainer>
                <CRow className={'justify-content-center'}>
                    <CCol md={'4'}>
                        <CCardGroup>
                            <CCard className={'p-4'}>
                                <CCardBody>
                                    {'hiii'}
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}