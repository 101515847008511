import CIcon from '@coreui/icons-react'

const itemsListArray = [
	{
		_tag: 'CSidebarNavItem',
		name: 'Dashboard',
		to: '/dashboard',
		icon: <CIcon name='cil-speedometer' customClasses='c-sidebar-nav-icon' />,
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'User Management',
		route: '/user-management',
		icon: 'cil-user',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Users',
				to: '/user-management/users/list',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Roles',
				to: '/user-management/roles/list',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Permissions',
				to: '/user-management/permissions/list',
			},
		],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Logger',
		route: '/logger',
		icon: 'cil-book',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Projects',
				to: '/logger/projects/list',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Task Types',
				to: '/logger/task-types/list',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Tasks',
				to: '/logger/tasks/list',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Daily Tasks',
				to: '/logger/daily-tasks/list',
			},
		],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'App Estimator',
		route: '/estimation',
		icon: 'cil-calculator',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Platforms',
				to: '/estimation/platforms/list',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Categories',
				to: '/estimation/categories/list',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Tasks',
				to: '/estimation/tasks/list',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Visitors',
				to: '/estimation/visitors/list',
			},
		],
	},
]

export default itemsListArray
