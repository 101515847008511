import CIcon from '@coreui/icons-react'
import { CSpinner, CWidgetSimple } from '@coreui/react'
import React from 'react'
import { WidgetButtonPropType } from './type'

export const WidgetButton = ({
	className,
	buttonTextLarge,
	buttonTextSmall,
	icon,
	iconSize,
	clickHandle,
	isDisabled,
	buttonColor,
	loading,
}: WidgetButtonPropType) => {
	return (
		<CWidgetSimple
			className={`mb-0  ${!loading ? 'pointer shadow ' : 'bg-light'} h-100 option-box ${className} text-${buttonColor}`}
			text={buttonTextLarge}
			header={buttonTextSmall}
			onClick={!loading && !isDisabled ? clickHandle : () => {}}
			style={isDisabled ? { cursor: 'not-allowed', background: 'lightgray' } : { cursor: 'pointer' }}
		>
			{loading && <CSpinner grow={true} color={buttonColor} type={'glow'} />}
			{icon && !loading && <CIcon name={icon} height={iconSize || 25} />}
		</CWidgetSimple>
	)
}
