import moment from 'moment'
import {
	DropdownItemType,
	CategoryListCustomType,
	CategoryResponseType,
	TaskListCustomType,
	TaskResponseType,
	PlatformListCustomType,
	PlatformResponseType,
	VisitorResponseType,
} from '../type'

/* start total pages */
export const getTotalPages = (records: number, recordsPerPage: number) => {
	if (records === 0 || null || undefined) {
		return 1
	} else {
		return Math.ceil(records / recordsPerPage)
	}
}
/* end total pages */

/* start get dropdown items */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
	const item = items.filter((item: DropdownItemType) => item.id === id)
	if (item.length > 0) {
		return item[0].title
	}
	return placeholder || 'Select here'
}
/* end get dropdown items *
/* start platform list */
export const getPlatformsForList = (platforms: PlatformResponseType[]) => {
	return platforms?.map((platform: PlatformResponseType) => {
		return {
			Id: platform.id,
			Name: platform.name,
			PriceFactor: platform.price_factor,
			Description: platform.description,
			Status: platform.status ? 'Active' : 'Not Active',
		} as PlatformListCustomType
	})
}
/* end platform list */

/* start task list */
export const getTasksForList = (tasks: TaskResponseType[]) => {
	return tasks?.map((task: TaskResponseType) => {
		return {
			Id: task.id,
			Name: task.name,
			EffortTime: task.effort_time,
			Platform: task.platform.name,
			Category: task.category.name,
			Description: task.description,
			Status: task.status ? 'Active' : 'Not Active',
		} as TaskListCustomType
	})
}
/* end task list */

/* start category list */
export const getCategoriesForList = (categories: CategoryResponseType[]) => {
	return categories?.map((category: CategoryResponseType) => {
		return {
			Id: category.id,
			Name: category.name,
			Platform: category.platform.name,
			Description: category.description,
			Status: category.status ? 'Active' : 'Not Active',
		} as CategoryListCustomType
	})
}
/* end category list */

/* start visitor list */
export const getVisitorsForList = (visitors: VisitorResponseType[]) => {
	return visitors?.map((visitor: VisitorResponseType) => {
		return {
			Id: visitor.id,
			Name: visitor.name,
			Email: visitor.email,
			Phone: visitor.phone,
			File: visitor?.file?.public_url,
			Date: moment(visitor.created_at).format('MMMM Do YYYY, h:mm:ss a'),
		} as any
	})
}
/* end visitor list */

export const getPlatformsForDropdown = (platforms: PlatformResponseType[]) => {
	return platforms?.map((platform: PlatformResponseType) => {
		return {
			id: platform.id,
			title: platform.name,
		} as DropdownItemType
	})
}

export const getCategoriesForDropdown = (categories: CategoryResponseType[]) => {
	return categories?.map((category: CategoryResponseType) => {
		return {
			id: category.id,
			title: category.name,
		} as DropdownItemType
	})
}

export const getTasksForDropdown = (tasks: TaskResponseType[]) => {
	return tasks?.map((task: TaskResponseType) => {
		return {
			id: task.id,
			title: task.name,
		} as DropdownItemType
	})
}

export const getFormattedCategories = (categories: DropdownItemType[]) => {
	let newList = [] as any
	categories.forEach((category: DropdownItemType) => {
		const categorySplit: any = category.title.split('.')
		if (categorySplit[0] in newList) {
			newList[categorySplit[0]].push({ id: category.id, title: categorySplit[1] })
		} else {
			newList[categorySplit[0]] = [{ id: category.id, title: categorySplit[1] }]
		}
	})
	let data = [] as any
	for (let key in newList) {
		data.push({ label: key, group: newList[key] })
	}
	return data
}

export const getPillColor = (text: string) => {
	switch (text) {
		case 'list':
			return 'primary'
		case 'create':
			return 'success'
		case 'show':
			return 'info'
		case 'update':
			return 'warning'
		case 'destroy':
			return 'danger'
		case 'status':
			return 'dark'
		default:
			return 'primary'
	}
}
