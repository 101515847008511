import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	getCategoryDropdownItems,
	getCategories,
	getTasks,
	getSelectedCategory,
	getSelectedTask,
	getSelectedPlatform,
	getPlatforms,
	getPlatformDropdownItems,
	getVisitors,
	getSelectedVisitor,
} from './api'
import { getCategoriesForDropdown, getPlatformsForDropdown } from './helper'
import {
	DropdownItemType,
	MetaType,
	CategoryListResponseType,
	CategoryResponseType,
	TaskListResponseType,
	TaskResponseType,
	PlatformListResponseType,
	PlatformResponseType,
	VisitorListResponseType,
	VisitorResponseType,
} from './type'

export const PlatformSlice = createSlice({
	name: 'platform',
	initialState: {
		/*start common*/
		loading: false as boolean,
		meta: {} as MetaType,
		/*end common*/

		/*start platform*/
		selectedPlatformPage: 1 as number,
		platforms: [] as PlatformResponseType[],
		selectedPlatform: {} as PlatformResponseType,
		platformDropdownItems: [] as DropdownItemType[],

		/*end platform*/

		/*start category*/
		selectedCategoryPage: 1 as number,
		categories: [] as CategoryResponseType[],
		selectedCategory: {} as CategoryResponseType,
		categoryDropdownItems: [] as DropdownItemType[],
		/*end category*/

		/*start task*/
		selectedTaskPage: 1 as number,
		tasks: [] as TaskResponseType[],
		selectedTask: {} as TaskResponseType,
		taskDropdownItems: [] as DropdownItemType[],
		/*end task*/

		/*start visitor*/
		selectedVisitorPage: 1 as number,
		visitors: [] as VisitorResponseType[],
		selectedVisitor: {} as VisitorResponseType,
		/*end visitor*/
	},
	reducers: {
		/*start common*/
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		setMeta: (state, action: PayloadAction<MetaType>) => {
			state.meta = action.payload
		},
		/*end common*/

		/*start platform*/
		setSelectedPlatformPage: (state, action: PayloadAction<number>) => {
			state.selectedPlatformPage = action.payload
		},
		setPlatforms: (state, action: PayloadAction<PlatformResponseType[]>) => {
			state.platforms = action.payload
		},
		setSelectedPlatform: (state, action: PayloadAction<PlatformResponseType>) => {
			state.selectedPlatform = action.payload
		},
		setPlatformDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
			state.platformDropdownItems = action.payload
		},
		/*end platform*/

		/*start task*/
		setSelectedTaskPage: (state, action: PayloadAction<number>) => {
			state.selectedTaskPage = action.payload
		},
		setTasks: (state, action: PayloadAction<TaskResponseType[]>) => {
			state.tasks = action.payload
		},
		setSelectedTask: (state, action: PayloadAction<TaskResponseType>) => {
			state.selectedTask = action.payload
		},
		setTaskDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
			state.taskDropdownItems = action.payload
		},
		/*end task*/

		/*start category*/
		setSelectedCategoryPage: (state, action: PayloadAction<number>) => {
			state.selectedCategoryPage = action.payload
		},
		setCategories: (state, action: PayloadAction<CategoryResponseType[]>) => {
			state.categories = action.payload
		},
		setSelectedCategory: (state, action: PayloadAction<CategoryResponseType>) => {
			state.selectedCategory = action.payload
		},
		setCategoryDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
			state.categoryDropdownItems = action.payload
		},
		/*end category*/

		/*start visitor*/
		setSelectedVisitorPage: (state, action: PayloadAction<number>) => {
			state.selectedVisitorPage = action.payload
		},
		setVisitors: (state, action: PayloadAction<VisitorResponseType[]>) => {
			state.visitors = action.payload
		},
		setSelectedVisitor: (state, action: PayloadAction<VisitorResponseType>) => {
			state.selectedVisitor = action.payload
		},
		/*end visitor*/
	},
})

export const {
	/*start common*/
	setLoading,
	setMeta,
	/*end common*/

	/*start platform*/
	setSelectedPlatformPage,
	setPlatforms,
	setSelectedPlatform,
	setPlatformDropdownItems,
	/*end platform*/

	/*start category*/
	setSelectedCategoryPage,
	setCategories,
	setSelectedCategory,
	setCategoryDropdownItems,
	/*end category*/

	/*start task*/
	setSelectedTaskPage,
	setTasks,
	setSelectedTask,
	/*end task*/

	/*start visitor*/
	setSelectedVisitorPage,
	setVisitors,
	setSelectedVisitor,
	/*end visitor*/
} = PlatformSlice.actions

/*start common*/

/*end common*/

/*start platform*/
export const loadPlatforms = (page: number, records: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: PlatformListResponseType = await getPlatforms(page, records)
	dispatch(setPlatforms(response.list))
	dispatch(setMeta(response.meta))
	dispatch(setLoading(false))
}

export const loadSelectedPlatform = (id: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: PlatformResponseType = await getSelectedPlatform(id)
	dispatch(setSelectedPlatform(response))
	dispatch(setLoading(false))
}

export const loadPlatformDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: { items: PlatformResponseType[] } = await getPlatformDropdownItems(sort, order)
	const dropdownItems = getPlatformsForDropdown(response.items)
	dispatch(setPlatformDropdownItems(dropdownItems))
	dispatch(setLoading(false))
}

/*end platform*/

/*start category*/
export const loadCategories = (page: number, records: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: CategoryListResponseType = await getCategories(page, records)
	dispatch(setCategories(response.list))
	dispatch(setMeta(response.meta))
	dispatch(setLoading(false))
}

export const loadSelectedCategory = (id: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: CategoryResponseType = await getSelectedCategory(id)
	dispatch(setSelectedCategory(response))
	dispatch(setLoading(false))
}

export const loadCategoryDropdownItems = (sort?: string, order?: string, platform_id?: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: { items: CategoryResponseType[] } = await getCategoryDropdownItems(sort, order, platform_id)
	const dropdownItems = getCategoriesForDropdown(response.items)
	dispatch(setCategoryDropdownItems(dropdownItems))
	dispatch(setLoading(false))
}

/*end category*/

/*start task*/
export const loadTasks = (page: number, records: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: TaskListResponseType = await getTasks(page, records)
	dispatch(setTasks(response.list))
	dispatch(setMeta(response.meta))
	dispatch(setLoading(false))
}

export const loadSelectedTask = (id: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: TaskResponseType = await getSelectedTask(id)
	dispatch(setSelectedTask(response))
	dispatch(setLoading(false))
}

/*end task*/

/*start visitor*/
export const loadVisitors = (page: number, records: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: VisitorListResponseType = await getVisitors(page, records)
	dispatch(setVisitors(response.list))
	dispatch(setMeta(response.meta))
	dispatch(setLoading(false))
}

export const loadSelectedVisitor = (id: number) => async (dispatch: any) => {
	dispatch(setLoading(true))
	const response: VisitorResponseType = await getSelectedVisitor(id)
	dispatch(setSelectedVisitor(response))
	dispatch(setLoading(false))
}
/*end visitor*/

/*start common*/
export const selectLoading = (state: any) => state.platform.loading
export const selectMeta = (state: any) => state.platform.meta
/*end common*/

/*start platform*/
export const selectSelectedPlatformPage = (state: any) => state.platform.selectedPlatformPage
export const selectPlatforms = (state: any) => state.platform.platforms
export const selectSelectedPlatform = (state: any) => state.platform.selectedPlatform
export const selectPlatformDropdownItems = (state: any) => state.platform.platformDropdownItems
/*end platform*/

/*start task*/
export const selectSelectedTaskPage = (state: any) => state.platform.selectedTaskPage
export const selectTasks = (state: any) => state.platform.tasks
export const selectSelectedTask = (state: any) => state.platform.selectedTask
export const selectTaskDropdownItems = (state: any) => state.platform.taskDropdownItems
/*end task*/

/*start category*/
export const selectSelectedCategoryPage = (state: any) => state.platform.selectedCategoryPage
export const selectCategories = (state: any) => state.platform.categories
export const selectSelectedCategory = (state: any) => state.platform.selectedCategory
export const selectCategoryDropdownItems = (state: any) => state.platform.categoryDropdownItems
/*end category*/

/*start visitor*/
export const selectSelectedVisitorPage = (state: any) => state.platform.selectedVisitorPage
export const selectVisitors = (state: any) => state.platform.visitors
export const selectSelectedVisitor = (state: any) => state.platform.selectedVisitor
/*end visitor*/

export default PlatformSlice.reducer
