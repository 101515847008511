import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Dashboard } from './features/dashboard'
import { Login } from './features/auth/login'
import { Logout } from './features/auth/logout'
import { GlobalLayout, LoaderOne } from './features/layout'
import { UserList, RoleList, PermissionList } from './features/user'
import { Suspense } from 'react'
import React from 'react'
import { CategoryList, PlatformList, TaskList, VisitorList } from './features/estimation'
import { ProjectList, TaskTypeList, TaskList as LoggerTaskList, DailyTask } from './features/logger'

function App() {
	return (
		<Router>
			<Suspense fallback={<LoaderOne />}>
				<Switch>
					<Route exact path='/'>
						<Login />
					</Route>

					<Route exact path='/logout'>
						<Logout />
					</Route>
					<Route exact path='/dashboard'>
						<GlobalLayout>
							<Dashboard />
						</GlobalLayout>
					</Route>
					<Route exact path='/user-management/users/list'>
						<GlobalLayout>
							<UserList />
						</GlobalLayout>
					</Route>
					<Route exact path='/user-management/roles/list'>
						<GlobalLayout>
							<RoleList />
						</GlobalLayout>
					</Route>
					<Route exact path='/user-management/permissions/list'>
						<GlobalLayout>
							<PermissionList />
						</GlobalLayout>
					</Route>
					<Route exact path='/estimation/platforms/list'>
						<GlobalLayout>
							<PlatformList />
						</GlobalLayout>
					</Route>
					<Route exact path='/estimation/categories/list'>
						<GlobalLayout>
							<CategoryList />
						</GlobalLayout>
					</Route>
					<Route exact path='/estimation/tasks/list'>
						<GlobalLayout>
							<TaskList />
						</GlobalLayout>
					</Route>
					<Route exact path='/estimation/visitors/list'>
						<GlobalLayout>
							<VisitorList />
						</GlobalLayout>
					</Route>
					<Route exact path='/logger/projects/list'>
						<GlobalLayout>
							<ProjectList />
						</GlobalLayout>
					</Route>
					<Route exact path='/logger/task-types/list'>
						<GlobalLayout>
							<TaskTypeList />
						</GlobalLayout>
					</Route>
					<Route exact path='/logger/tasks/list'>
						<GlobalLayout>
							<LoggerTaskList />
						</GlobalLayout>
					</Route>
					<Route exact path='/logger/daily-tasks/list'>
						<GlobalLayout>
							<DailyTask />
						</GlobalLayout>
					</Route>
				</Switch>
			</Suspense>
		</Router>
	)
}

export default App
