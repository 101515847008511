import { confirmAlert } from "react-confirm-alert";

export const isEmail = (email: string) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email !== '' && regex.test(email)) {
        return true;
    }
    return false;
}

export const isPassword = (password: string) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (password !== '' && regex.test(password)) {
        return true;
    }
    return false;
}

export const deleteWithConfirmation = (deleteAction: () => any, cancelAction: () => any) => {
    confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this?',
        closeOnClickOutside: false,
        buttons: [
            {
                label: 'Yes',
                onClick: deleteAction
            },
            {
                label: 'No',
                onClick: cancelAction
            }
        ]
    });
}