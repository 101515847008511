import React from 'react'
const PlatformList = React.lazy(() => import('./platforms/list'))
const PlatformCreate = React.lazy(() => import('./platforms/modals/createPlatform'))
const PlatformEdit = React.lazy(() => import('./platforms/modals/editPlatform'))
const PlatformOptionBox = React.lazy(() => import('./platforms/modals/options'))
const TaskList = React.lazy(() => import('./tasks/list'))
const TaskCreate = React.lazy(() => import('./tasks/modals/createTask'))
const TaskEdit = React.lazy(() => import('./tasks/modals/editTask'))
const TaskOptionBox = React.lazy(() => import('./tasks/modals/options'))
const CategoryList = React.lazy(() => import('./categories/list'))
const CategoryCreate = React.lazy(() => import('./categories/modals/createCategory'))
const CategoryEdit = React.lazy(() => import('./categories/modals/editCategory'))
const CategoryOptionBox = React.lazy(() => import('./categories/modals/options'))
const VisitorList = React.lazy(() => import('./visitors/list'))

export {
	/* start platform components */
	PlatformList,
	PlatformCreate,
	PlatformEdit,
	PlatformOptionBox,
	/* end platform components */

	/* start task components */
	TaskList,
	TaskCreate,
	TaskEdit,
	TaskOptionBox,
	/* end task components */

	/* start category componenets */
	CategoryList,
	CategoryCreate,
	CategoryEdit,
	CategoryOptionBox,
	/* end category componenets */

	/* start visitors components */
	VisitorList,
	/* end visitors components */
}
