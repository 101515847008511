import { toast } from 'react-toastify';
import {apiUrl} from '../app/config';

const getQueryString = (path: string) =>{
    const queryStringArray = path.split('?');
    let newString = [] as any;
    if(queryStringArray.length === 2){
        const query = queryStringArray[1];
        const queryArray = query.split('&');
        queryArray.forEach(query=>{
            const keyPair = query.split('=');
            if(keyPair.length === 2 && keyPair[1] !== undefined && keyPair[1] !== "" && keyPair[1] !== 'undefined'){
                newString.push(keyPair.join('='));
            }
        });
    }
    return queryStringArray[0]+(newString.length >0 ? '?'+newString.join('&'):'');
}

const createUrl = (urlPath: string) => {
    return `${apiUrl}${getQueryString(urlPath)}`
}

const getHttpHeaders = () => {
    const token = localStorage.getItem("token");
    const locale = localStorage.getItem("locale") || 'en';
    const headers: any = {'Content-Type': 'application/json','Accept': 'application/json'}
    headers['X-localization'] = locale;
    if (token) headers['Authorization'] = `Bearer ${token}`;
    return headers;
}

const getHttpFormHeaders = () => {
    const token = localStorage.getItem("token");
    const locale = localStorage.getItem("locale") || 'en';
    const headers: any = {'Accept': 'application/json'}
    headers['X-localization'] = locale;
    if (token) headers['Authorization'] = `Bearer ${token}`;
    return headers;
}

class ApiWrapper{

    public async wrapPromise (promise: Promise<Response>) {
        return this.wrapResponse(await promise);
    }

    public async wrapResponse(rawResponse: Response) {
        const response = await rawResponse.json();
        if(!rawResponse.ok){
            toast.error(response.message || 'Server Not Found');
            if(response.statusCode === 401 && response.message==='Expired token' && localStorage.getItem('refresh')){
                const auth = await this.post(`auth/user/refresh`, {refresh:localStorage.getItem('refresh')});
                if(auth.token && auth.refresh){
                    localStorage.setItem('token',auth.token);
                    localStorage.setItem('refresh',auth.refresh);
                    window.location.reload();
                }
            }
        }
        return response;
    }

    public async post(urlPath: string, body:any){
        const url = createUrl(urlPath)
        const rawResponse = await fetch(url, {
            'method': 'POST',
            'headers': getHttpHeaders(),
            'body': JSON.stringify(body)
        });
        return this.wrapResponse(rawResponse);
    }

    public async patch(urlPath: string){
        const url = createUrl(urlPath)
        const rawResponse = await fetch(url, {
            'method': 'PATCH',
            'headers': getHttpHeaders()
        });
        return this.wrapResponse(rawResponse);
    }

    public async put(urlPath: string, body:any){
        const url = createUrl(urlPath)
        const rawResponse = await fetch(url, {
            'method': 'PUT',
            'headers': getHttpHeaders(),
            'body': JSON.stringify(body)
        });
        return this.wrapResponse(rawResponse);
    }

    public async get(urlPath: string){
        const url = createUrl(urlPath)
        const rawResponse =  await fetch(url, {
            'method': 'GET',
            'headers': getHttpHeaders(),
        });
        return this.wrapResponse(rawResponse);
    }

    public async delete(urlPath: string){
        const url = createUrl(urlPath)
        const rawResponse =  await fetch(url, {
            'method': 'DELETE',
            'headers': getHttpHeaders(),
        });
        return this.wrapResponse(rawResponse);
    }

    public async blob(urlPath: string){
        const url = createUrl(urlPath)
        const rawResponse =  await fetch(url, {
            'method': 'GET',
            'headers': getHttpHeaders(),
        });
        return rawResponse.blob();
    }

    public async upload(urlPath: string, body:any){
        const url = createUrl(urlPath)
        const rawResponse = await fetch(url, {
            'method': 'POST',
            'headers': getHttpFormHeaders(),
            'body': body
        });
        return this.wrapResponse(rawResponse);
    }

}

export default new ApiWrapper();