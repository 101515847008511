import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Redirect } from 'react-router-dom';
import { refresh, selectIsLoggedIn } from '../store';

const authLayout = (Component: React.ComponentType<any>) => (props: any) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    useEffect(() => {
        if (!isLoggedIn) {
            dispatch(refresh());
        }
        setLoading(false)
    },[dispatch, isLoggedIn]);

    if (isLoggedIn || loading) return <Component {...props}/>
    return (<Redirect to="/"/>);
}

export default authLayout;