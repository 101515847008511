import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProfile } from "./api";
import { GlobalSidebarShowType } from "./type";
import { AuthUserProfileResponseType, ProfileResponseType, SettingResponseType } from "./type/response";

export const LayoutSlice = createSlice({
    name: 'layout',
    initialState: {
        /* start comman*/
        loading:false as boolean,
        version:process.env.REACT_APP_VERSION as string,
        /* end comman*/

        /* start sidebar*/
        globalSidebar: {type: 'set', sidebarShow: true } as GlobalSidebarShowType,
        /* end sidebar*/

        /* start user*/
        profile: {} as AuthUserProfileResponseType,
        scopes: [] as string[],
        settings: [] as SettingResponseType[]
        /* end  user*/

    },
    reducers:{
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setGlobalSidebar: (state, action:PayloadAction<GlobalSidebarShowType>) => {
            state.globalSidebar = action.payload;
        },
        setProfile: (state, action:PayloadAction<AuthUserProfileResponseType>) => {
            state.profile = action.payload;
        },
        setScopes: (state, action:PayloadAction<string[]>) => {
            state.scopes = action.payload;
            localStorage.setItem('scopes', JSON.stringify(action.payload));
        },
        setSettings: (state, action:PayloadAction<SettingResponseType[]>) => {
            state.settings = action.payload;
            localStorage.setItem('settings', JSON.stringify(action.payload));
        }
   }
});

export const {
    setLoading,
    setGlobalSidebar,
    setProfile,
    setScopes,
    setSettings
} = LayoutSlice.actions;

export const getUserProfile = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    if(localStorage.getItem('token')){
        const profile: ProfileResponseType = await getProfile();
        dispatch(setProfile(profile.user));
        dispatch(setScopes(profile.scope));
        dispatch(setSettings(profile.setting));
    }
    dispatch(setLoading(false));
}

export const selectLoading = (state:any) => state.layout.loading;
export const selectVersion = (state:any) => state.layout.version;
export const selectGlobalSidebar = (state: any) => state.layout.globalSidebar;
export const selectUserProfile = (state: any) =>  state.layout.profile;
export const selectUserScopes = (state: any) => state.layout.scopes;

export default LayoutSlice.reducer;