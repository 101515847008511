import { 
    CCreateElement, 
    CImg, 
    CSidebar, 
    CSidebarBrand, 
    CSidebarMinimizer, 
    CSidebarNav,
    CSidebarNavDivider,
    CSidebarNavDropdown,
    CSidebarNavItem,
    CSidebarNavTitle 
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import authLayout from '../../../auth/layout/auth';
import itemsListArray from './items';
import { Span } from '../../styled';
import { useEffect } from 'react';
import { getUserProfile, selectGlobalSidebar, setGlobalSidebar } from '../../store';
import { GlobalSidebarShowType } from '../../type';
import logo from '../../../../assets/static/logo.png'
import logo_sm from '../../../../assets/static/logo-sm.png'

const GlobalSidebarMenu = () => {
    
    const dispatch = useDispatch();
    // const linkObject = useLocation();
    //const scopeslist: string[] = JSON.parse(localStorage.getItem('scopes') || '{}')//useSelector(selectUserScopes);
    const sidebar: GlobalSidebarShowType = useSelector(selectGlobalSidebar);

    useEffect(()=>{
        dispatch(getUserProfile());
    },[dispatch]);

    const sidemenu = (val: any) => {
        dispatch(setGlobalSidebar({ type: 'set', sidebarShow: val }))
    }
   
    return (
        <>
            <CSidebar
                show={sidebar.sidebarShow}
                onShowChange={(val: boolean) => sidemenu(val)}
            >
                <CSidebarBrand className="d-md-down-none bg-white" to="/">
                    <Span className={'c-sidebar-brand-full text-bold'}>
                        <CImg
                            src={logo}
                            className="img-fluid" width={100}
                            alt="logo-image"
                        />
                    </Span>
                    <Span className={'c-sidebar-brand-minimized'}><CImg
                        src={logo_sm}
                        className="img-fluid" width={35}
                        alt="logo-image"
                    /></Span>
                </CSidebarBrand>
                <CSidebarNav>
                    <CCreateElement
                        items={itemsListArray}
                        components={{
                            CSidebarNavDivider,
                            CSidebarNavDropdown,
                            CSidebarNavItem,
                            CSidebarNavTitle
                        }}
                    />
                </CSidebarNav>
                <CSidebarMinimizer className="c-d-md-down-none" />
            </CSidebar>
        </>
    )
}
const GlobalSidebar = authLayout(GlobalSidebarMenu);
export default GlobalSidebar;
