import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProjectDropdownItems, getProjects, getTasks, getSelectedProject, getSelectedTask, getSelectedTaskType, getTaskTypes, getTaskTypeDropdownItems, getClients, getSelectedClient, getClientDropdownItems } from "./api";
import { getClientsForDropdown, getProjectsForDropdown, getTaskTypesForDropdown } from "./helper";
import { ClientListResponseType, ClientResponseType, DropdownItemType, MetaType, ProjectListResponseType, ProjectResponseType, TaskListResponseType, TaskResponseType, TaskTypeListResponseType, TaskTypeResponseType } from "./type";

export const LoggerSlice = createSlice({
    name: 'logger',
    initialState: {
        /*start common*/
        loading:false as boolean,
        meta: {} as MetaType,
        /*end common*/

        /*start client*/
        selectedClientPage: 1 as number,
        clients: [] as ClientResponseType[],
        selectedClient: {} as ClientResponseType,
        clientDropdownItems: [] as DropdownItemType[],
        /*end client*/

        /*start tasktype*/
        selectedTaskTypePage: 1 as number,
        tasktypes: [] as TaskTypeResponseType[],
        selectedTaskType: {} as TaskTypeResponseType,
        tasktypeDropdownItems: [] as DropdownItemType[],

        /*end tasktype*/
        /*start project*/
        selectedProjectPage: 1 as number,
        projects: [] as ProjectResponseType[],
        selectedProject: {} as ProjectResponseType,
        projectDropdownItems: [] as DropdownItemType[],
        /*end project*/

        /*start task*/
        selectedTaskPage: 1 as number,
        tasks: [] as TaskResponseType[],
        selectedTask: {} as TaskResponseType,
        taskDropdownItems: [] as DropdownItemType[]
        /*end task*/

    },
    reducers:{
        /*start common*/
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMeta: (state, action:PayloadAction<MetaType>) => {
            state.meta = action.payload;
        },
        /*end common*/

        /*start client*/
        setSelectedClientPage: (state, action:PayloadAction<number>) => {
            state.selectedClientPage = action.payload;
        },
        setClients: (state, action:PayloadAction<ClientResponseType[]>) => {
            state.clients = action.payload;
        },
        setSelectedClient: (state, action: PayloadAction<ClientResponseType>) => {
            state.selectedClient = action.payload;
        },
        setClientDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.clientDropdownItems = action.payload;
        },
        /*end client*/

        /*start tasktype*/
        setSelectedTaskTypePage: (state, action: PayloadAction<number>) => {
            state.selectedTaskTypePage = action.payload;
        },
        setTaskTypes: (state, action: PayloadAction<TaskTypeResponseType[]>) => {
            state.tasktypes = action.payload;
        },
        setSelectedTaskType: (state, action: PayloadAction<TaskTypeResponseType>) => {
            state.selectedTaskType = action.payload;
        },
        setTaskTypeDropdownItems: (state, action: PayloadAction<DropdownItemType[]>) => {
            state.tasktypeDropdownItems = action.payload;
        },
        /*end tasktype*/

        /*start task*/
        setSelectedTaskPage: (state, action:PayloadAction<number>) => {
            state.selectedTaskPage = action.payload;
        },
        setTasks: (state, action:PayloadAction<TaskResponseType[]>) => {
            state.tasks = action.payload;
        },
        setSelectedTask: (state, action:PayloadAction<TaskResponseType>) => {
            state.selectedTask = action.payload;
        },
        setTaskDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.taskDropdownItems = action.payload;
        },
        /*end task*/

        /*start project*/
        setSelectedProjectPage: (state, action:PayloadAction<number>) => {
            state.selectedProjectPage = action.payload;
        },
        setProjects: (state, action:PayloadAction<ProjectResponseType[]>) => {
            state.projects = action.payload;
        },
        setSelectedProject: (state, action:PayloadAction<ProjectResponseType>) => {
            state.selectedProject = action.payload;
        },
        setProjectDropdownItems: (state, action:PayloadAction<DropdownItemType[]>) => {
            state.projectDropdownItems = action.payload;
        }
        /*end project*/
   }
});

export const {
    /*start common*/
    setLoading,
    setMeta,
    /*end common*/

    /*start client*/
    setSelectedClientPage,
    setClients,
    setSelectedClient,
    setClientDropdownItems,
    /*end client*/
    
    /*start tasktype*/
    setSelectedTaskTypePage,
    setTaskTypes,
    setSelectedTaskType,
    setTaskTypeDropdownItems,
    /*end tasktype*/

    /*start project*/
    setSelectedProjectPage,
    setProjects,
    setSelectedProject,
    setProjectDropdownItems,
    /*end project*/

    /*start task*/
    setSelectedTaskPage,
    setTasks,
    setSelectedTask,
    /*end task*/
    
} = LoggerSlice.actions;

/*start common*/

/*end common*/
/*start client*/
export const loadClients = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: ClientListResponseType = await getClients(page, records);
    dispatch(setClients(response.list));
    dispatch(setMeta(response.meta));
    dispatch(setLoading(false));
}

export const loadSelectedClient = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: ClientResponseType = await getSelectedClient(id);
    dispatch(setSelectedClient(response));
    dispatch(setLoading(false));
}

export const loadClientDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: { items: ClientResponseType[] } = await getClientDropdownItems(sort, order);
    const dropdownItems = getClientsForDropdown(response.items);
    dispatch(setClientDropdownItems(dropdownItems));
    dispatch(setLoading(false));
}

/*end client*/

/*start tasktype*/
export const loadTaskTypes = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: TaskTypeListResponseType = await getTaskTypes(page, records);
    dispatch(setTaskTypes(response.list));
    dispatch(setMeta(response.meta));
    dispatch(setLoading(false));
}

export const loadSelectedTaskType = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: TaskTypeResponseType = await getSelectedTaskType(id);
    dispatch(setSelectedTaskType(response));
    dispatch(setLoading(false));
}

export const loadTaskTypeDropdownItems = (sort?: string, order?: string) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: { items: TaskTypeResponseType[] } = await getTaskTypeDropdownItems(sort, order);
    const dropdownItems = getTaskTypesForDropdown(response.items);
    dispatch(setTaskTypeDropdownItems(dropdownItems));
    dispatch(setLoading(false));
}

/*end tasktype*/

/*start project*/
export const loadProjects = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: ProjectListResponseType = await getProjects(page, records);
    dispatch(setProjects(response.list));
    dispatch(setMeta(response.meta));
    dispatch(setLoading(false));
}

export const loadSelectedProject = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: ProjectResponseType = await getSelectedProject(id);
    dispatch(setSelectedProject(response));
    dispatch(setLoading(false));
}

export const loadProjectDropdownItems = (sort?: string, order?: string, tasktype_id?: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: { items: ProjectResponseType[] } = await getProjectDropdownItems(sort, order);
    const dropdownItems = getProjectsForDropdown(response.items);
    dispatch(setProjectDropdownItems(dropdownItems));
    dispatch(setLoading(false));
}

/*end project*/

/*start task*/
export const loadTasks = (page: number, records: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: TaskListResponseType = await getTasks(page, records);
    dispatch(setTasks(response.list));
    dispatch(setMeta(response.meta));
    dispatch(setLoading(false));
}

export const loadSelectedTask = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const response: TaskResponseType = await getSelectedTask(id);
    dispatch(setSelectedTask(response));
    dispatch(setLoading(false));
}

/*end task*/

/*start common*/
export const selectLoading = (state: any) => state.logger.loading;
export const selectMeta = (state: any) => state.logger.meta;
/*end common*/

/*start tasktype*/
export const selectSelectedClientPage = (state: any) => state.logger.selectedClientPage;
export const selectClients = (state: any) => state.logger.clients;
export const selectSelectedClient = (state: any) => state.logger.selectedClient;
export const selectClientDropdownItems = (state: any) => state.logger.clientDropdownItems;
/*end tasktype*/

/*start tasktype*/
export const selectSelectedTaskTypePage = (state: any) => state.logger.selectedTaskTypePage;
export const selectTaskTypes = (state: any) => state.logger.tasktypes;
export const selectSelectedTaskType = (state: any) => state.logger.selectedTaskType;
export const selectTaskTypeDropdownItems = (state: any) => state.logger.tasktypeDropdownItems;
/*end tasktype*/


/*start task*/
export const selectSelectedTaskPage = (state:any) => state.logger.selectedTaskPage;
export const selectTasks = (state: any) => state.logger.tasks;
export const selectSelectedTask = (state: any) => state.logger.selectedTask;
export const selectTaskDropdownItems = (state: any) => state.logger.taskDropdownItems;
/*end task*/

/*start project*/
export const selectSelectedProjectPage = (state: any) => state.logger.selectedProjectPage;
export const selectProjects = (state: any) => state.logger.projects;
export const selectSelectedProject = (state: any) => state.logger.selectedProject;
export const selectProjectDropdownItems = (state: any) => state.logger.projectDropdownItems;
/*end project*/

export default LoggerSlice.reducer;