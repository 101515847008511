import ApiWrapper from '../../service/ApiWrapper'

// Platform API start.

export const getPlatforms = async (page: number, records: number) => {
	const path = `estimation/platforms?page=${page}&records=${records}`
	return await ApiWrapper.get(path)
}

export const changePlatformStatus = async (id: number) => {
	const path = `estimation/platforms/${id}`
	return await ApiWrapper.patch(path)
}

export const createNewPlatform = async (data: any) => {
	const path = `estimation/platforms`
	return await ApiWrapper.post(path, data)
}

export const getSelectedPlatform = async (id: number) => {
	const path = `estimation/platforms/${id}`
	return await ApiWrapper.get(path)
}

export const patchSelectedPlatform = async (id: number, data: any) => {
	const path = `estimation/platforms/${id}`
	return await ApiWrapper.put(path, data)
}

export const deleteSelectedPlatform = async (id: number) => {
	const path = `estimation/platforms/${id}`
	return await ApiWrapper.delete(path)
}

export const uploadPlatformImage = async (data: any) => {
	const path = `common/upload/image`
	return await ApiWrapper.upload(path, data)
}

export const getPlatformDropdownItems = async (sort?: string, order?: string) => {
	const path = `estimation/platforms/dropdown?sort=${sort}&order=${order}`
	return await ApiWrapper.get(path)
}

// Platform API end.

// Category API start.

export const getCategories = async (page: number, records: number) => {
	const path = `estimation/categories?page=${page}&records=${records}`
	return await ApiWrapper.get(path)
}

export const getCategoryDropdownItems = async (sort?: string, order?: string, platform_id?: number) => {
	const path = `estimation/categories/dropdown?sort=${sort}&order=${order}&platform_id=${platform_id}`
	return await ApiWrapper.get(path)
}

export const changeCategoryStatus = async (id: number) => {
	const path = `estimation/categories/${id}`
	return await ApiWrapper.patch(path)
}

export const createNewCategory = async (data: any) => {
	const path = `estimation/categories`
	return await ApiWrapper.post(path, data)
}

export const getSelectedCategory = async (id: number) => {
	const path = `estimation/categories/${id}`
	return await ApiWrapper.get(path)
}

export const patchSelectedCategory = async (id: number, data: any) => {
	const path = `estimation/categories/${id}`
	return await ApiWrapper.put(path, data)
}

export const deleteSelectedCategory = async (id: number) => {
	const path = `estimation/categories/${id}`
	return await ApiWrapper.delete(path)
}

// Category API end.

// Task API start.

export const getTasks = async (page: number, records: number) => {
	const path = `estimation/tasks?page=${page}&records=${records}`
	return await ApiWrapper.get(path)
}

export const changeTaskStatus = async (id: number) => {
	const path = `estimation/tasks/${id}`
	return await ApiWrapper.patch(path)
}

export const createNewTask = async (data: any) => {
	const path = `estimation/tasks`
	return await ApiWrapper.post(path, data)
}

export const getSelectedTask = async (id: number) => {
	const path = `estimation/tasks/${id}`
	return await ApiWrapper.get(path)
}

export const patchSelectedTask = async (id: number, data: any) => {
	const path = `estimation/tasks/${id}`
	return await ApiWrapper.put(path, data)
}

export const deleteSelectedTask = async (id: number) => {
	const path = `estimation/tasks/${id}`
	return await ApiWrapper.delete(path)
}

// Task API end.

// Visitor API start.

export const getVisitors = async (page: number, records: number) => {
	const path = `estimation/customers?page=${page}&records=${records}`
	return await ApiWrapper.get(path)
}

export const getSelectedVisitor = async (id: number) => {
	const path = `estimation/customers/${id}`
	return await ApiWrapper.get(path)
}

// Visitor API end.
