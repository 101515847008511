import { ClientResponseType, DropdownItemType, ProjectListCustomType, ProjectResponseType, TaskListCustomType, TaskResponseType, TaskTypeListCustomType, TaskTypeResponseType } from "../type";

/* start total pages */
export const getTotalPages = (records: number, recordsPerPage: number) => {
    if (records === 0 || null || undefined) {
        return 1;
    }else {
        return Math.ceil(records/recordsPerPage);
    }
}
/* end total pages */

/* start get dropdown items */
export const getDropdownLabelById = (items: DropdownItemType[], id: any, placeholder?: string) => {
    const item = items.filter((item: DropdownItemType) => item.id === id);
    if (item.length > 0) {
        return item[0].title;
    }
    return placeholder || 'Select here';
}
/* end get dropdown items *
/* start tasktype list */
export const getTaskTypesForList = (tasktypes: TaskTypeResponseType[]) => {
    return tasktypes?.map((tasktype: TaskTypeResponseType) => {
        return {
            Id: tasktype.id,
            Name: tasktype.name,
            Description:tasktype.description,
            Status: (tasktype.status ? 'Active' : 'Not Active'),
        } as TaskTypeListCustomType
    });
}
/* end tasktype list */

/* start task list */
export const getTasksForList = (tasks: TaskResponseType[]) => {
    return tasks.map((task: TaskResponseType) => {
        return {
            Id:task.id, 
            Title: task.title,
            TaskType: task.logger_task_type.name,
            Project: task.project.name,
            EstimatedHours:task.estimated_hours,
            Description: task.description,
            TaskStatus:task.task_status,
            Status:(task.status ? 'Active':'Not Active')
        } as TaskListCustomType
    });
}
/* end task list */

/* start project list */
export const getProjectsForList = (projects: ProjectResponseType[]) => {
    return projects?.map((project: ProjectResponseType) => {
        return {
            Id:project.id, 
            Name: project.name,
            Client:project.client.name,
            Description: project.description,
            Status:project.status?'Active':'Not Active'
        } as ProjectListCustomType
    });
}
/* end project list */

export const getTaskTypesForDropdown = (tasktypes: TaskTypeResponseType[]) => {
    return tasktypes?.map((tasktype: TaskTypeResponseType) => {
        return {
            id: tasktype.id,
            title: tasktype.name
        } as DropdownItemType
    });
}

export const getClientsForDropdown = (clients: ClientResponseType[]) => {
    return clients?.map((client: ClientResponseType) => {
        return {
            id: client.id,
            title: client.name
        } as DropdownItemType
    });
}

export const getProjectsForDropdown = (projects: ProjectResponseType[]) => {
    return projects?.map((project: ProjectResponseType) => {
        return {
            id:project.id, 
            title:project.name
        } as DropdownItemType
    });    
}

export const getTasksForDropdown = (tasks: TaskResponseType[]) => {
    return tasks?.map((task: TaskResponseType) => {
        return {
            id:task.id, 
            title:task.title
        } as DropdownItemType
    });    
}

export const getFormattedProjects = (projects:DropdownItemType[])=>{
    let newList = [] as any;
    projects.forEach((project: DropdownItemType) => {
        const projectSplit: any = project.title.split('.');
        if(projectSplit[0] in newList){
            newList[projectSplit[0]].push({id: project.id, title: projectSplit[1]});
        }else{
            newList[projectSplit[0]] = [{id: project.id, title: projectSplit[1]}]
        }
    });
    let data = [] as any;
    for(let key in newList){
        data.push({label: key,group: newList[key]});
    }
    return data
}

export const getPillColor = (text: string) => {
    switch (text) {
        case 'list':
            return 'primary';
        case 'create':
            return 'success';
        case 'show':
            return 'info';
        case 'update':
            return 'warning';
        case 'destroy':
            return 'danger';
        case 'status':
            return 'dark';
        default:
            return 'primary';
    }
}
