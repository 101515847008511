import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import AuthSlice from '../features/auth/store';
import LayoutReducer from '../features/layout/store';
import UserReducer from '../features/user/store';
import PlatformReducer from '../features/estimation/store';
import LoggerReducer from '../features/logger/store';
export const store = configureStore({
  reducer: {
    auth: AuthSlice,    
    layout: LayoutReducer,
    user: UserReducer,
    platform: PlatformReducer,
    logger: LoggerReducer,

  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
