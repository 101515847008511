import React from 'react';

const ProjectList = React.lazy(() => import('./projects/list'));
const ProjectCreate = React.lazy(() => import('./projects/modals/createProject'));
const ProjectEdit = React.lazy(() => import('./projects/modals/editProject'));
const ProjectOptionBox = React.lazy(() => import('./projects/modals/options'));
const TaskTypeList = React.lazy(()=>import('./task_types/list'));
const TaskTypeCreate = React.lazy(()=>import('./task_types/modals/createTaskType'));
const TaskTypeEdit = React.lazy(()=>import('./task_types/modals/editTaskType'));
const TaskTypeOptionBox = React.lazy(()=>import('./task_types/modals/options'));
const TaskList = React.lazy(()=>import('./tasks/list'));
const TaskCreate = React.lazy(()=>import('./tasks/modals/createTask'));
const TaskEdit = React.lazy(()=>import('./tasks/modals/editTask'));
const TaskOptionBox = React.lazy(() => import('./tasks/modals/options'));
const DailyTask = React.lazy(() => import('./daily_tasks/list'));
const AddDailyTask = React.lazy(() => import('./daily_tasks/modals/addTask'));

export {
    /* start user */
    TaskTypeList,
    TaskTypeCreate,
    TaskTypeEdit,
    TaskTypeOptionBox,
    /* end user */

    /* start role */
    TaskList,
    TaskCreate,
    TaskEdit,
    TaskOptionBox,
    /* end role */
    
    /* start permission */
    ProjectList,
    ProjectCreate,
    ProjectEdit,
    ProjectOptionBox,
    /* end permission */
    DailyTask,
    AddDailyTask
}