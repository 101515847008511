import ApiWrapper from "../../service/ApiWrapper";

// Client API start.
export const getClients = async (page: number, records: number) => {
    const path = `logger/clients?page=${page}&records=${records}`;
    return await ApiWrapper.get(path);
}

export const changeClientStatus = async (id: number) => {
    const path = `logger/clients/${id}`;
    return await ApiWrapper.patch(path);
}

export const createNewClient = async (data: any) => {
    const path = `logger/clients`;
    return await ApiWrapper.post(path, data);
}

export const getSelectedClient = async (id: number) => {
    const path = `logger/clients/${id}`;
    return await ApiWrapper.get(path);
}

export const patchSelectedClient = async (id: number, data: any) => {
    const path = `logger/clients/${id}`;
    return await ApiWrapper.put(path, data);
}

export const deleteSelectedClients = async (id: number) => {
    const path = `logger/clients/${id}`;
    return await ApiWrapper.delete(path);
}

export const getClientDropdownItems = async (sort?: string, order?: string) => {
    const path = `logger/clients/dropdown?sort=${sort}&order=${order}`;
    return await ApiWrapper.get(path);
}

// Client API end.

// Project API start.

export const getProjects = async (page: number, records: number) => {
    const path = `logger/projects?page=${page}&records=${records}`;
    return await ApiWrapper.get(path);
}

export const changeProjectStatus = async (id:number) => {
    const path = `logger/projects/${id}`;
    return await ApiWrapper.patch(path);
}

export const createNewProject = async (data: any) => {
    const path = `logger/projects`;
    return await ApiWrapper.post(path, data);
}

export const getSelectedProject = async (id: number) => {
    const path = `logger/projects/${id}`;
    return await ApiWrapper.get(path);
}

export const patchSelectedProject = async (id: number, data: any) => {
    const path = `logger/projects/${id}`;
    return await ApiWrapper.put(path, data);
}

export const deleteSelectedProject = async (id: number) => {
    const path = `logger/projects/${id}`;
    return await ApiWrapper.delete(path);
}

export const getProjectDropdownItems = async (sort?: string, order?: string) => {
    const path = `logger/projects/dropdown?sort=${sort}&order=${order}`;
    return await ApiWrapper.get(path);
}

// Project API end.

// TaskType API start.

export const getTaskTypes = async (page: number, records: number) => {
    const path = `logger/task-types?page=${page}&records=${records}`;
    return await ApiWrapper.get(path);
}

export const getTaskTypeDropdownItems = async (sort?: string, order?: string,project_id?:number) => {
    const path = `logger/task-types/dropdown?sort=${sort}&order=${order}&project_id=${project_id}`;
    return await ApiWrapper.get(path);
}

export const changeTaskTypeStatus = async (id: number) => {
    const path = `logger/task-types/${id}`;
    return await ApiWrapper.patch(path);
}

export const createNewTaskType = async (data: any) => {
    const path = `logger/task-types`;
    return await ApiWrapper.post(path, data);
}

export const getSelectedTaskType = async (id: number) => {
    const path = `logger/task-types/${id}`;
    return await ApiWrapper.get(path);
}

export const patchSelectedTaskType = async (id: number, data: any) => {
    const path = `logger/task-types/${id}`;
    return await ApiWrapper.put(path, data);
}

export const deleteSelectedTaskType = async (id: number) => {
    const path = `logger/task-types/${id}`;
    return await ApiWrapper.delete(path);
}

export const getTaskTypesDropdownItems = async (sort?: string, order?: string) => {
    const path = `logger/task-types/dropdown?sort=${sort}&order=${order}`;
    return await ApiWrapper.get(path);
}
// TaskType API end.

// Task API start.

export const getTasks = async (page: number, records: number) => {
    const path = `logger/tasks?page=${page}&records=${records}`;
    return await ApiWrapper.get(path);
}

export const changeTaskStatus = async (id: number) => {
    const path = `logger/tasks/${id}`;
    return await ApiWrapper.patch(path);
}

export const createNewTask = async (data: any) => {
    const path = `logger/tasks`;
    return await ApiWrapper.post(path, data);
}

export const getSelectedTask = async (id: number) => {
    const path = `logger/tasks/${id}`;
    return await ApiWrapper.get(path);
}

export const patchSelectedTask = async (id: number, data: any) => {
    const path = `logger/tasks/${id}`;
    return await ApiWrapper.put(path, data);
}

export const deleteSelectedTask = async (id: number) => {
    const path = `logger/tasks/${id}`;
    return await ApiWrapper.delete(path);
}

export const getTasksDropdownItems = async (sort?: string, order?: string) => {
    const path = `logger/tasks/dropdown?sort=${sort}&order=${order}`;
    return await ApiWrapper.get(path);
}
// Task API end.
