import { CImg } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ImageUploadTypeProp } from './type';
import './index.css';
import CIcon from '@coreui/icons-react';

const Div = styled.div<{width?: number}>`
    ${({width}) => width && `width: ${width}px;`}
`;
//const Hr = styled.hr``;
const Input = styled.input`display: none;`;

export const ImageUpload = ({
    thumb,
    height,
    width,
    onUploadSelect,
    clearPreview
}:ImageUploadTypeProp) => {
    const previewThumb = thumb || `https://dummyimage.com/${width}x${height}/e6dfe6/7f84c7`;
    const [preview, setPreview] = useState<string|null|any>();
    const imageRef = useRef<HTMLInputElement | null>(null);
    const openOsExplorer = () =>{
        imageRef.current?.click();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(onUploadSelect){
            const files = event.currentTarget.files as any
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = function (e) {
                setPreview(reader.result);
            }
            return onUploadSelect(files);
        }
        throw new Error("Provide either onChange or onValueChange to Input component.")
    }

    useEffect(() => {
        if (clearPreview) {
            setPreview(null);
        }
    }, [clearPreview]);

    return (
        <Div className={'main-image-div'}>
            <Div className={'text-center'}>
                {!preview && <Div className={'option-image-div'}>
                    <Div><CIcon className={'m-1 p-2 bg-light shadow image-container rounded float-left'} name={'cil-image-plus'} size={'8xl'} onClick={openOsExplorer}/></Div>
                </Div>}
                {preview && <Div className={'option-image-div text-center'}>
                    <Div><CIcon className={'m-1 p-2 bg-danger shadow image-container rounded'} name={'cil-trash'} size={'8xl'} onClick={()=>setPreview(undefined)}/></Div>
                </Div>}
                <CImg 
                    src={preview || previewThumb} 
                    className={'img-fluid img-thumbnail'} 
                    alt={thumb || 'image'} 
                    width={width}
                    height={height}
                />
            </Div>
            <Input ref={imageRef} type={'file'} accept="image/*" onChange={handleChange}/>
        </Div>
    )
} 